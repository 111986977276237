import React, { createContext, useContext, useState } from 'react';

import * as API from '../lib/api';

const ResumesContext = createContext();

// Provider component that wraps your app and makes user object
// available to any child component that calls useResumes().
export function ResumesProvider({ children }) {
  const resumes = useResumesProvider();
  return (
    <ResumesContext.Provider value={resumes}>
      {children}
    </ResumesContext.Provider>
  );
}

// Hook for child components to get the user object
// and re-render when it changes.
const useResumes = () => useContext(ResumesContext);

function useResumesProvider() {
  const [resumes, setResumes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [currentResume, setCurrentResume] = useState(null);
  const [currentResumeHTML, setCurrentResumeHTML] = useState('');
  const [error, setError] = useState(null);
  const [totalResumes, setTotalResumes] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedResult, setSearchedResult] = useState({});

  // list resumes for user
  async function listResumes(title, page, country) {
    setIsLoading(true);
    setError(null);
    try {
      // list resumes
      let resumes, total;
      const from = page * 10 - 10;
      const commonTitle = title.toLowerCase();
      const countryKey = country || 'all';
      const currentPage =
        searchedResult[commonTitle] &&
        searchedResult[commonTitle][countryKey] &&
        searchedResult[commonTitle][countryKey].allPages &&
        searchedResult[commonTitle][countryKey].allPages?.[page];
      if (currentPage) {
        resumes = currentPage;
        total = searchedResult[commonTitle][countryKey]?.total;
      } else {
        let qs = `title=${title}&from=${from}&size=10`;
        if (country) {
          qs += `&country=${country}`;
        }
        const result = await API.listResumes(qs);
        resumes = result.resumes;
        total = result.total.value;
      }
      const copyAllPages =
        searchedResult[commonTitle] &&
        searchedResult[commonTitle][countryKey]?.allPages;
      const copySearchedResumes = {
        ...searchedResult,
        [commonTitle]: {
          ...searchedResult[commonTitle],
          [countryKey]: {
            allPages: {
              ...copyAllPages,
              [page]: resumes,
            },
            total,
          },
        },
      };
      setSearchedResult(copySearchedResumes);
      setResumes(resumes);
      setTotalResumes(total);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function downloadResume() {
    setIsDownloading(true);
    setError(null);
    try {
      if (currentResume) {
        const {
          settings: { template, color },
        } = currentResume;
        const buffer = await API.exportDocument({
          html: currentResumeHTML,
          template,
          color,
          gender: 'male',
        });
        const file = new Blob([buffer], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else {
        throw new Error('Resume not found');
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setIsDownloading(false);
    }
  }

  async function getResumeHTML(userId, resumeId) {
    setIsLoading(true);
    setError(null);
    try {
      let resume;
      // get resume from exiting resumes list
      const mappedResumes = Object.entries(searchedResult).map((o) =>
        [].concat.apply(
          [],
          Object.entries(o[1]).map((oo) =>
            Object.entries(oo[1].allPages).map((ooo) => ooo[1])
          )
        )
      );
      const allResumes = mappedResumes.flat().flat();
      const findResume = allResumes.find(
        (resume) => resume.resumeId === resumeId
      );
      if (findResume) {
        resume = findResume;
      } else {
        resume = await API.getResume(userId, resumeId);
      }
      if (resume) {
        setCurrentResume(resume);
        const html = await API.getDocumentHtml({
          file: resume,
          gender: 'male',
        });
        setCurrentResumeHTML(html);
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSetCurrentPage = (title, page, country) => {
    setCurrentPage(page);
    if (title) {
      return listResumes(title, page, country);
    }
  };

  return {
    resumes,
    isLoading,
    isDownloading,
    setResumes,
    listResumes,
    downloadResume,
    error,
    getResumeHTML,
    currentResumeHTML,
    currentPage,
    setCurrentPage: handleSetCurrentPage,
    totalResumes,
  };
}

export default useResumes;
