import React, { createContext, useContext, useState } from 'react';

import * as API from '../lib/api';

const SuggestionsContext = createContext();

// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
export function SuggestionsProvider({ children }) {
  const auth = useSuggestionsProvider();
  return (
    <SuggestionsContext.Provider value={auth}>
      {children}
    </SuggestionsContext.Provider>
  );
}

// Hook for child components to get the auth object
// and re-render when it changes.
const useSuggestions = () => useContext(SuggestionsContext);

// Provider hook that creates auth object and handles state
function useSuggestionsProvider() {
  const [jobs, setJobs] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  async function getJobs(text, lang = 'en') {
    try {
      const res = await API.getJobs({
        text,
        lang,
      });
      const jobs = res.map((j) => j.title);
      setJobs(jobs);
      setError('');
      return true;
    } catch (error) {
      const errorResponse = error.response && error.response.data;
      setError(errorResponse);
    }
  }

  async function getSuggestions(job, type, language) {
    try {
      setLoading(true);
      const requestBody = {
        type,
        lang: language,
      };

      if (job) {
        requestBody.job = job;
      }

      const res = await API.getSuggestions(requestBody);
      const suggestionResult = res.map((suggestion) => suggestion.text);
      setSuggestions(suggestionResult);
      setLoading(false);
      setError('');
      setSuccess(true);
      return true;
    } catch (error) {
      const errorResponse = error.response && error.response.data;
      setError(errorResponse);
    }
  }

  return {
    getSuggestions,
    jobs,
    suggestions,
    loading,
    getJobs,
    setJobs,
    error,
    success,
  };
}

export default useSuggestions;
