import { useMemo } from 'react';
import Autosuggest from 'react-autosuggest';

import debounce from 'lodash.debounce';

import useSuggestions from '../hooks/useSuggestions';

const AutoComplete = ({ language, value, setValue }) => {
  const { getJobs, jobs, setJobs } = useSuggestions();

  const getSuggestions = (value, lang) => {
    getJobs(value, lang);
  };

  const debounceLoadData = useMemo(() => {
    return debounce(getSuggestions, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSuggestionValue = (suggestion) => suggestion;

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value: newValue }) => {
    if (value !== newValue) {
      debounceLoadData(newValue, language);
    }
  };

  const renderSuggestion = (suggestion) => (
    <div className="py-1 px-2">{suggestion}</div>
  );

  const onSuggestionsClearRequested = () => {
    setJobs([]);
  };

  const inputProps = {
    placeholder: 'Job title',
    value,
    onChange: onChange,
    className:
      'appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm',
  };

  return (
    <div>
      <label className="block text-sm font-bold text-gray-700 mb-2">
        Job Title
      </label>
      <Autosuggest
        suggestions={jobs}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        theme={{
          suggestionsContainer: 'max-h-60 overflow-y-scroll border-indigo-500',
          suggestion: 'cursor-pointer',
        }}
      />
    </div>
  );
};

export default AutoComplete;
