import { classNames } from '../utils/helpers';

const Notification = ({ message, color }) => (
  <div
    className={classNames(
      'text-white px-6 py-2 border-0 rounded relative mb-4',
      color === 'danger' && 'bg-red-500',
      color === 'success' && 'bg-green-500'
    )}
  >
    <span className="inline-block align-middle mr-8 text-sm">{message}</span>
  </div>
);

export default Notification;
