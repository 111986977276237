import { useEffect } from 'react';

import { DownloadIcon } from '@heroicons/react/solid';
import { useRoute } from 'wouter';

import Loader from '../components/Loader';
import Preview from '../components/Preview';
import useResumes from '../hooks/useResumes';

const ResumePreview = () => {
  const {
    loading,
    getResumeHTML,
    downloadResume,
    currentResumeHTML,
    isDownloading,
  } = useResumes();
  const [, params] = useRoute('/resume/:userId/:resumeId');
  const { userId, resumeId } = params;

  const getResumeDetails = async () => {
    await getResumeHTML(userId, resumeId);
  };

  const renderLoading = () => {
    return (
      <div>
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle cx="12" cy="12" r="10" stroke="#000000" strokeWidth="4" />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </div>
    );
  };

  useEffect(() => {
    getResumeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="min-h-screen flex items-center flex-col bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      {currentResumeHTML && (
        <div className="flex">
          <Preview currentResumeHTML={currentResumeHTML} />
          <button
            className="ml-4 flex h-full"
            onClick={downloadResume}
            disabled={loading}
          >
            {isDownloading ? (
              renderLoading()
            ) : (
              <DownloadIcon
                className="h-8 w-8 cursor-pointer"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default ResumePreview;
