import { useState } from 'react';

import Notification from '../components/Notification';
import useAuth from '../hooks/useAuth';

const Login = () => {
  const auth = useAuth();
  const [error, setError] = useState(null);
  const [loginDetails, setLoginDetails] = useState({ email: '', password: '' });
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [usePassword, setUsePassword] = useState(true);

  async function submitHandler(e) {
    e.preventDefault();
    setIsDisabled(true);

    if (!loginDetails.email.endsWith('@resumedone.io')) {
      setIsDisabled(false);
      setError('Your email must end with `@resumedone.io`');
      return;
    }

    try {
      const result = await auth.login(loginDetails, usePassword);
      if (result?.link) {
        window.location = result.link;
        return;
      }
      setIsDone(true);
      setError(null);
    } catch (e) {
      setError(e.message);
      setIsDisabled(false);
    }
  }

  const changeHandler = (e) =>
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });

  return (
    <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-xl w-full space-y-8">
        <div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Login</h2>
          <h4 className="mt-2 text-2xl text-gray-900">
            A magic sign-in link will be sent to your admin account
          </h4>
        </div>
        {!isDone && (
          <form className="mt-8 space-y-6" onSubmit={submitHandler}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label
                  className="block text-grey-darker text-sm font-bold mb-2"
                  htmlFor="email-address"
                >
                  Admin email
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={changeHandler}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="you@resumedone.io"
                />
              </div>
            </div>
            {usePassword && (
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label
                    className="block text-grey-darker text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Admin Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    onChange={changeHandler}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="you@resumedone.io"
                  />
                </div>
              </div>
            )}
            {error && <Notification color="danger" message={error} />}
            <div>
              <button
                type="submit"
                disabled={isDisabled}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3" />
                {usePassword ? 'Login' : 'Request Link'}
              </button>
              <button
                type="button"
                disabled={isDisabled}
                onClick={() => setUsePassword(!usePassword)}
                className="outline-none w-full mt-6 mr-1 mb-1 px-3 py-1 bg-transparent text-sm text-center font-bold text-blue-500 uppercase focus:outline-none"
              >
                {usePassword ? 'Use Link' : 'Use Password'}
              </button>
            </div>
          </form>
        )}
        {isDone && (
          <Notification
            color="success"
            message=" Sent! Please check your email for a magic login link"
          />
        )}
      </div>
    </div>
  );
};

export default Login;
