import React from 'react';
import ReactDOM from 'react-dom';

import Cookies from 'cookies-js';

import App from './App';
import { AuthProvider } from './hooks/useAuth';
import { ResumesProvider } from './hooks/useResumes';
import { SuggestionsProvider } from './hooks/useSuggestions';
import reportWebVitals from './reportWebVitals';

import './index.css';

const authToken = Cookies.get('AuthToken');

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider value={authToken}>
      <SuggestionsProvider>
        <ResumesProvider>
          <App />
        </ResumesProvider>
      </SuggestionsProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
