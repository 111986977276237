import React, { Fragment } from 'react';

import { Redirect, Route } from 'wouter';

import useAuth from '../hooks/useAuth';
import Navbar from './Navbar';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useAuth();

  return (
    <Route {...rest}>
      {(props) =>
        !auth.user ? (
          <Redirect to="/login" />
        ) : (
          <Fragment>
            <Navbar /> <Component {...props} />
          </Fragment>
        )
      }
    </Route>
  );
};

export const PublicRoute = ({ component: Component, ...rest }) => {
  const auth = useAuth();
  return (
    <Route {...rest}>
      {(props) => (auth.user ? <Redirect to="/" /> : <Component {...props} />)}
    </Route>
  );
};
