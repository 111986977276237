import { useEffect, useState } from 'react';

import Dropdown from '../components/Dropdown';
import Table from '../components/Table';
import useResumes from '../hooks/useResumes';
import countries from '../utils/countries';

const Countries = [{ title: 'All', value: '' }, ...countries];

const ResumeSearch = () => {
  const { setCurrentPage, setResumes } = useResumes();
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState(Countries[0]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value) {
      setCurrentPage(value, 1, selected.value);
    }
  };

  useEffect(() => {
    return () => {
      setCurrentPage(null, 1);
      setResumes([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-h-screen flex items-center flex-col bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-xl w-full space-y-8">
        <form onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <Dropdown
                options={Countries}
                title="Country"
                selected={selected}
                handleChange={setSelected}
              />
            </div>
          </div>
          <div className="mt-5">
            <label className="block text-sm font-bold text-gray-700 mb-2">
              Job title
            </label>
            <div className="pt-2 relative mx-auto text-gray-600">
              <input
                className="w-full border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                type="search"
                name="search"
                placeholder="Enter Job title"
                value={value}
                onChange={handleChange}
              />
              <button
                type="submit"
                className="absolute right-0 top-0 mt-5 mr-4 focus:outline-none"
              >
                <svg
                  className="text-gray-600 h-4 w-4 fill-current"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 56.966 56.966"
                  style={{ enableBackground: 'new 0 0 56.966 56.966' }}
                  xmlSpace="preserve"
                  width="512px"
                  height="512px"
                >
                  <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                </svg>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="max-w-4xl w-full space-y-8 m-5">
        <Table title={value} country={selected.value} />
      </div>
    </div>
  );
};

export default ResumeSearch;
