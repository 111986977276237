import { format } from 'date-fns';
import { Link } from 'wouter';

import useResumes from '../hooks/useResumes';
import { classNames } from '../utils/helpers';
import Loader from './Loader';
import Pagination from './Pagination';

const columns = ['Id', 'Job Title', 'Created on', 'Actions'];

const Table = ({ title, country }) => {
  const {
    currentPage,
    setCurrentPage,
    resumes,
    totalResumes,
    isLoading,
  } = useResumes();

  const handleSetCurrentPage = (page) => {
    setCurrentPage(title, page, country);
  };

  return (
    <div className="flex flex-col">
      {isLoading && <Loader />}
      <div
        className={classNames(
          '-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8',
          (isLoading || !resumes.length) && 'hidden'
        )}
      >
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column, index) => (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      key={index}
                    >
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {resumes.map((resume) => (
                  <tr key={resume.resumeId}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-bold text-gray-900">
                        {resume.resumeId.substr(0, 6)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {resume.details.title}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {typeof resume.createdAt === 'object'
                          ? 'Unknown'
                          : format(new Date(resume.createdAt), 'do MMM yyyy')}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <Link
                        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        href={`/resume/${resume.userId}/${resume.resumeId}`}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              totalRecords={totalResumes}
              setCurrentPage={handleSetCurrentPage}
              currentPage={currentPage}
              disableAllButton={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
