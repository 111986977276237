import 'tailwindcss/base.css';

import React from 'react';

import Loader from './components/Loader';
import { PrivateRoute, PublicRoute } from './components/Routes';
import useAuth from './hooks/useAuth';
import Login from './pages/Login';
import PreWritten from './pages/PreWritten';
import ResumePreview from './pages/ResumePreview';
import ResumeSearch from './pages/ResumeSearch';
import Verify from './pages/Verify';

function App() {
  const auth = useAuth();

  if (auth.isLoading) {
    return <Loader />;
  }

  return (
    <div className="App">
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/login/verify" component={Verify} />
      <PrivateRoute path="/" component={PreWritten} />
      <PrivateRoute path="/pre-written" component={PreWritten} />
      <PrivateRoute path="/resume-search" component={ResumeSearch} />
      <PrivateRoute
        path="/resume/:userId/:resumeId"
        component={ResumePreview}
      />
    </div>
  );
}

export default App;
