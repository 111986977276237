import React, { useEffect, useState } from 'react';

import { useLocation } from 'wouter';

import Loader from '../components/Loader';
import Notification from '../components/Notification';
import useAuth from '../hooks/useAuth';

const getValue = (param) =>
  new URLSearchParams(window.location.search).get(param);

function Verify() {
  const auth = useAuth();
  const [, setLocation] = useLocation();
  const { token } = { token: getValue('token') };

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) {
      setIsLoading(false);
      setError('No token provided');
    } else {
      auth
        .verifyLogin(token)
        .then(() => setLocation('/'))
        .catch((err) => {
          setError(err.message);
          setIsLoading(false);
        });
    }
  }, [token, setLocation, auth]);

  if (error) {
    return <Notification message={error} color="danger" />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return null;
}

export default Verify;
