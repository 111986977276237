import Cookies from 'cookies-js';
import qs from 'query-string';

// Creates an authenticatd HTTP request
async function createRequest({
  url,
  method = 'GET',
  headers = {},
  body,
  query,
  userId, // Override
}) {
  const token = Cookies.get('AuthToken');
  if (!token) {
    throw new Error('AuthToken not set');
  }

  const options = {
    method,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  // Add admin override user ID
  if (userId) {
    options.headers['x-user-id'] = userId;
  }
  if (body) {
    options.body = JSON.stringify(body);
  }

  let _url = url;
  if (query) {
    _url = `${_url}?${qs.stringify(query)}`;
  }

  const res = await fetch(_url, options);

  const contentType = res.headers.get('Content-Type');
  const bufferContentTypes = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
  ];

  if (bufferContentTypes.includes(contentType)) {
    return res.arrayBuffer();
  }

  const text = await res.text();

  if (res.status > 399) {
    throw new Error(text);
  }

  try {
    const json = JSON.parse(text);
    return json;
  } catch (e) {
    return text;
  }
}

// Creates an HTTP request to the API.
export const createAPIRequest = ({ path, ...rest }) =>
  createRequest({ url: `${process.env.REACT_APP_API_URL}${path}`, ...rest });

// Creates an HTTP request to the Matches API.
export const createMatchesRequest = ({ path, ...rest }) =>
  createRequest({ url: `${process.env.REACT_APP_MATCH_URL}${path}`, ...rest });

// Creates an HTTP request to the Webapp API.
export const createWebappRequest = ({ path, ...rest }) =>
  createRequest({ url: `${process.env.REACT_APP_WEBAPP_URL}${path}`, ...rest });
