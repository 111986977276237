import {
  createAPIRequest,
  createMatchesRequest,
  createWebappRequest,
} from './_requests';

export const listResumes = (qs) =>
  createMatchesRequest({
    method: 'GET',
    path: `/resumes?${qs}`,
  });

export const getResume = (userId, resumeId) =>
  createAPIRequest({
    method: 'GET',
    path: `/getResume/${resumeId}`,
    userId,
  });

export const getDocumentHtml = ({ file, source = 'resume', gender }) =>
  createWebappRequest({
    method: 'POST',
    path: '/api/export-html',
    body: {
      file,
      source,
      redneg: gender,
    },
  });

export const exportDocument = ({
  html,
  template,
  color,
  fileType = 'pdf',
  gender,
}) =>
  createAPIRequest({
    method: 'POST',
    path: '/export',
    body: {
      html,
      fileType,
      template,
      color,
      gender,
    },
  });

export const getSuggestions = (options) =>
  createAPIRequest({
    method: 'POST',
    path: '/getSuggestions',
    body: options,
  });

export const getJobs = (options) =>
  createAPIRequest({
    method: 'POST',
    path: '/getJobs',
    body: options,
  });
