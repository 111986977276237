import { useState } from 'react';

import AutoComplete from '../components/AutoComplete';
import Dropdown from '../components/Dropdown';
import Loader from '../components/Loader';
import Notification from '../components/Notification';
import useSuggestions from '../hooks/useSuggestions';
import { classNames, copyToClipboard } from '../utils/helpers';

const types = [
  {
    title: 'Summary',
    value: 'summary',
  },
  {
    title: 'Experience',
    value: 'experience',
  },
  {
    title: 'Education',
    value: 'education',
  },
  {
    title: 'Skills',
    value: 'skill',
  },
];

const languages = [
  { text: 'English', value: 'en' },
  { text: 'Spanish', value: 'es' },
  { text: 'Turkish', value: 'tr' },
  { text: 'Indonasian', value: 'id' },
  { text: 'French', value: 'fr' },
];

const PreWritten = () => {
  const { suggestions, loading, error, success } = useSuggestions();
  const [selected, setSelected] = useState(types[0]);
  const [jobTitle, setJobTitle] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const { getSuggestions } = useSuggestions();

  const handleSubmit = () => {
    getSuggestions(jobTitle, selected.value, selectedLanguage);
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.name);
  };

  return (
    <div className="min-h-screen flex items-center flex-col bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl w-full space-y-8">
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label className="block text-sm font-bold text-gray-700 mb-2">
              Language
            </label>
            {languages.map((language, index) => (
              <label className="inline-flex items-center mr-3" key={index}>
                <input
                  type="radio"
                  className="form-radio h-5 w-5 text-gray-600"
                  name={language.value}
                  checked={language.value === selectedLanguage}
                  onChange={handleLanguageChange}
                />
                <span className="ml-2 text-gray-700">{language.text}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <AutoComplete
              language={selectedLanguage}
              value={jobTitle}
              setValue={setJobTitle}
            />
          </div>
        </div>
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <Dropdown
              options={types}
              title="Type"
              selected={selected}
              handleChange={setSelected}
            />
          </div>
        </div>

        {error && <Notification message={error} color="danger" />}

        <div className="rounded-md shadow-sm -space-y-px">
          <button
            type="button"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={handleSubmit}
            disabled={loading}
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3" />
            Get Suggestions
          </button>
        </div>
      </div>

      <div className="max-w-4xl	w-full space-y-8 mt-10">
        {!suggestions.length && success && (
          <h3 className="text-3xl text-center mb-2">No Result Found</h3>
        )}
        {!!suggestions.length && (
          <h3 className="text-3xl text-center mb-2">Suggestions</h3>
        )}
        {loading && <Loader />}
        <div className={classNames(loading && 'hidden')}>
          {suggestions.map((suggestion, index) => (
            <li
              className="mt-2 text-1xl text-gray-900 cursor-pointer"
              key={index}
              data-value={suggestion}
              onClick={copyToClipboard}
            >
              {suggestion}
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PreWritten;
