import { useEffect, useState } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

import { classNames } from '../utils/helpers';

const buttonClass =
  'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 cursor-pointer focus:outline-none';

const Pagination = ({
  totalRecords,
  perPage = 10,
  pageBound = 10,
  currentPage,
  setCurrentPage,
  disableAllButton,
}) => {
  const [upperPageBound, setUpperPageBound] = useState(10);
  const [lowerPageBound, setLowerPageBound] = useState(0);
  const [prevActive, setPrevActive] = useState('disabled');
  const [nextActive, setNextActive] = useState('');

  const totalPage = Math.ceil(totalRecords / perPage);

  useEffect(() => {
    setNextActive(totalRecords <= 10 ? 'disabled' : '');
  }, [totalRecords]);

  const setPrevAndNextBtnClass = (listId) => {
    setNextActive('disabled');
    setPrevActive('disabled');
    if (totalPage === listId && totalPage > 1) {
      setPrevActive('');
    } else if (listId === 1 && totalPage > 1) {
      setNextActive('');
    } else if (totalPage > 1) {
      setNextActive('');
      setPrevActive('');
    }
  };

  const btnIncrementClick = () => {
    setUpperPageBound(upperPageBound + pageBound);
    setLowerPageBound(lowerPageBound + pageBound);
    const listId = upperPageBound + 1;
    setCurrentPage(listId);
    setPrevAndNextBtnClass(listId);
  };

  const btnDecrementClick = () => {
    setUpperPageBound(upperPageBound - pageBound);
    setLowerPageBound(lowerPageBound - pageBound);
    const listId = upperPageBound - pageBound;
    setCurrentPage(listId);
    setPrevAndNextBtnClass(listId);
  };

  const btnPrevClick = () => {
    if (prevActive === 'disabled') return;
    if ((currentPage - 1) % pageBound === 0) {
      setUpperPageBound(upperPageBound - pageBound);
      setLowerPageBound(lowerPageBound - pageBound);
    }
    const listId = currentPage - 1;
    setCurrentPage(listId);
    setPrevAndNextBtnClass(listId);
  };
  const btnNextClick = () => {
    if (nextActive === 'disabled') return;
    if (currentPage + 1 > upperPageBound) {
      setUpperPageBound(upperPageBound + pageBound);
      setLowerPageBound(lowerPageBound + pageBound);
    }
    let listId = currentPage + 1;
    setCurrentPage(listId);
    setPrevAndNextBtnClass(listId);
  };

  const renderPages = (isNull, increment) => {
    if (isNull) return null;
    const action = increment ? btnIncrementClick : btnDecrementClick;
    return (
      <button
        onClick={action}
        className={buttonClass}
        disabled={disableAllButton}
      >
        <span> &hellip; </span>
      </button>
    );
  };

  const handleClick = (event) => {
    const listId = Number(event.target.dataset.value);
    if (listId === currentPage) return;
    setCurrentPage(listId);
    setPrevAndNextBtnClass(listId);
  };

  const renderNumbers = () => {
    const numbers = [];
    for (let i = 1; i <= totalPage; i++) {
      if (
        (i === 1 && currentPage === 1) ||
        (i < upperPageBound + 1 && i > lowerPageBound)
      ) {
        numbers.push(
          <button
            onClick={handleClick}
            data-value={i}
            key={i}
            className={classNames(
              buttonClass,
              currentPage === i && 'bg-gray-400'
            )}
            disabled={disableAllButton}
          >
            {i}
          </button>
        );
      }
    }
    return numbers;
  };

  const renderPrevBtn = () => (
    <button
      className={classNames(
        buttonClass,
        prevActive && 'bg-gray-100 cursor-not-allowed'
      )}
      onClick={btnPrevClick}
      disabled={prevActive || disableAllButton}
    >
      <span className="sr-only">Previous</span>
      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
    </button>
  );

  const renderNextBtn = () => (
    <button
      className={classNames(
        buttonClass,
        nextActive && 'bg-gray-100 cursor-not-allowed'
      )}
      onClick={btnNextClick}
      disabled={nextActive || disableAllButton}
    >
      <span className="sr-only">Next</span>
      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
    </button>
  );

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="sm:flex-1 sm:flex sm:items-center sm:justify-center">
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            {renderPrevBtn()}
            {renderPages(!(lowerPageBound >= 1), false)}
            {renderNumbers()}
            {renderPages(!(totalPage > upperPageBound), true)}
            {renderNextBtn()}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
