export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const copyToClipboard = ({
  target: {
    dataset: { value },
  },
}) => {
  let tempInput = document.createElement('input');
  tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
};
